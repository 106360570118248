import axios from "../../../api/axios";
import { useEffect, useState } from "react";
import "./DisplayClients.css";
import { FaPlus } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { RiDeleteBin2Line } from "react-icons/ri";
import AddClient from "../AddClient/AddClient";
import EditClient from "../EditClient/EditClient";
import Modal from "../../Modal/Modal";

const CLIENTS_URL = "/api/client";

function DisplayClients() {
    const [clients, setClients] = useState([]);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [clientToEdit, setClientToEdit] = useState(null);

    const toggleAddModal = () => {
        setAddModalOpen(!isAddModalOpen);
    };

    const toggleEditModal = () => {
        setEditModalOpen(!isEditModalOpen);
    };

    async function deleteClient(id) {
        try {
            const userConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce client ?");
            if (userConfirmed) {
                await axios.delete(`${CLIENTS_URL}/${id}`);
                OnInit();
            } else {
                console.log("Suppression annulée");
            }
        } catch (err) {
            console.error("Error deleting client:", err);
        }
    }

    const OnInit = async () => {

        try {
            const response = await axios.get(CLIENTS_URL);
            setClients(response?.data);
        } catch (err) {

        }
    };

    useEffect(() => {
        OnInit();
    }, [OnInit]);
    
    const handleEditClient = (client) => {
        setClientToEdit(client);
        toggleEditModal();
    };

    return (
        <div className="main-content">
            <button onClick={toggleAddModal} className="addBtn">
                <FaPlus color="white" />
            </button>

            <Modal show={isAddModalOpen} onClose={toggleAddModal}>
                <AddClient
                    onSuccess={() => {
                        toggleAddModal();
                        OnInit();
                    }}
                />
            </Modal>

            <Modal show={isEditModalOpen} onClose={toggleEditModal}>
                <EditClient
                    client={clientToEdit}
                    onSuccess={() => {
                        toggleEditModal();
                        OnInit();
                    }}
                />
            </Modal>

            <div className="overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-light dark:bg-gray-700 dark:text-white">
                        <tr>
                            <th scope="col" className="px-6 py-3">Entreprise</th>
                            <th scope="col" className="px-6 py-3">ICE</th>
                            <th scope="col" className="px-6 py-3">Telephone</th>
                            <th scope="col" className="px-6 py-3">E-mail</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((client, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4">{client.entreprise}</td>
                                <td className="px-6 py-4">{client.ice}</td>
                                <td className="px-6 py-4">{client.telephone}</td>
                                <td className="px-6 py-4">{client.email}</td>
                                <td className="action px-6 py-4">
                                    <IoSettingsOutline
                                        color="green"
                                        onClick={() => handleEditClient(client)}
                                    />
                                    <RiDeleteBin2Line
                                        color="red"
                                        onClick={() => deleteClient(client._id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DisplayClients;
