import axios from "../../../api/axios";
import {useEffect, useState} from "react";
import "../DisplayClients/DisplayClients.css";
import {FaPlus, FaRegEye} from "react-icons/fa";
import {RiDeleteBin2Line} from "react-icons/ri";
import {Link, useNavigate} from "react-router-dom";
import {IoSettingsOutline} from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";

const GET_DEVIS_URL = '/api/devis/details'
const DELETE_DEVIS_URL = '/api/devis'


function DisplayDevis() {
    const [devis, setdevis] = useState([]);
    const navigate = useNavigate();

    async function deleteClient(id) {
        try {
            const userConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce devis ?");
            if (userConfirmed) {
                await axios.delete(`${DELETE_DEVIS_URL}/${id}`);
                OnInit();
            } else {
                console.log("Suppression annulée");
            }
        } catch (err) {
            console.error("Error deleting Devis:", err);
        }
    }


    const OnInit = async () => {

        try {
            const response = await axios.get(GET_DEVIS_URL);
            setdevis(response?.data);
        } catch (err) {
            console.error("Error getting data:", err);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    useEffect(() => {
        OnInit();
    }, []);

    const toggleStatus = async (id) => {
        try {
            const response = await axios.put(`/api/devis/status/${id}`);
            OnInit();
        } catch (error) {
            console.error("Error toggling status:", error.message);
        }
    };

    return (

        <div className="main-content">
            <Link to="/add-devis" type="button" className='addBtn'>
                <FaPlus color="white" />
            </Link>
            <div className="overflow-x-auto shadow-md sm:rounded-lg ">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead
                        className="text-xs text-gray-700 uppercase bg-light dark:bg-gray-700 dark:text-white">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Entreprise
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date De Creation
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Montant HT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Montant TTC
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        devis.map((item, index) => {
                            const montantHT = item.devisItems.reduce((total, devisItem) => total + (devisItem.qte * devisItem.price), 0);

                            return  <tr key={index}>
                            <td className="px-6 py-4">{item._id}</td>
                            <td className="px-6 py-4">{item.entreprise}</td>
                            <td className="px-6 py-4">{formatDate(item.date)}</td>
                            <td className="px-6 py-4">{montantHT.toFixed(2)}</td>
                            <td className="px-6 py-4">{item.devisMontant.toFixed(2)}</td>
                            <td
                                className={`px-6 py-4 font-semibold ${
                                    item.devisStatus === "En attente"
                                        ? "text-gray-500"
                                        : item.devisStatus === "Accepté"
                                        ? "text-green-500"
                                        : item.devisStatus === "Rejeté"
                                        ? "text-red-500"
                                        : ""
                                }`}
                            >
                                {item.devisStatus}
                            </td>
                            <td className="action px-6 py-4">
                            <IoIosCheckmarkCircle 
                                 onClick={() => toggleStatus(item._id)} 
                                className="cursor-pointer" 
                                color={item.devisStatus === "Accepté" ? "green" : "gray"} 
                                    />
                            <FaRegEye
                                    color="#1e40af"
                                    onClick={() => navigate(`/devis/${item?._id}`)}
                                />
                            <IoSettingsOutline
                                    color="green"
                                    onClick={() => navigate(`/devis/edit/${item._id}`)}
                                />
                            <RiDeleteBin2Line
                                    color="red"
                                    onClick={() => deleteClient(item?._id)}
                                />
                                
                            </td>
                        </tr>
                        })
                    }
                    </tbody>

                </table>
            </div>
        </div>
    );
}

export default DisplayDevis;
