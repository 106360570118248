import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import Sidebar from "../../Sidebar/Sidebar";
import Navbar from "../../Navbar/Navbar";
import "../EditDevis/EditDevis.css";

const DEVIS_URL = '/api/devis/details'; 
const ADD_FACTURE_URL = '/api/facture/add';
const CLIENTS_URL = '/api/client';

function AddFacture() {
    const succRef = useRef();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [devis, setDevis] = useState([]);
    const [selectedDevis, setSelectedDevis] = useState(null);
    const [showSansDevisForm, setShowSansDevisForm] = useState(false);
    const [items, setItems] = useState([{ designation: "", qte: 1, price: 0 }]);
    const [destinataire, setDestinataire] = useState("");
    const [devisObject, setDEvisObject] = useState("");
    const [devise, setDevise] = useState("MAD");
    const [totalHt, setTotalHt] = useState(0);
    const [tva, setTva] = useState(0);
    const [mttc, setMttc] = useState(0);
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const [modeDePaiement, setModeDePaiement] = useState("VIREMENT");

    useEffect(() => {
        const fetchDevis = async () => {
            try {
                const response = await axios.get(DEVIS_URL);
                setDevis(response?.data);
            } catch (err) {
                console.error("Error fetching devis", err);
            }
        };

        fetchDevis();
    }, []);

    const OnInit = async () => {
        try {
            const response = await axios.get(CLIENTS_URL);
            setClients(response?.data);
        } catch (err) {
            console.error("Error fetching clients", err);
        }
    };

    useEffect(() => {
        OnInit();
    }, []);

    const calculateTotalHt = () => {
        return items.reduce((total, item) => total + (item.qte * item.price), 0);
    };

    const calculateTva = (total) => {
        return (total * 0.20).toFixed(2);
    };

    const calculateMttc = (total, tvaAmount) => {
        return (total + parseFloat(tvaAmount)).toFixed(2);
    };

    useEffect(() => {
        const newTotalHt = calculateTotalHt();
        const newTva = calculateTva(newTotalHt);
        const newMttc = calculateMttc(newTotalHt, newTva);

        setTotalHt(newTotalHt);
        setTva(newTva);
        setMttc(newMttc);
    }, [items]);
    
    const handleSelectDevis = async (devisId) => {
        if (devisId === "sansDevis") {
            setShowSansDevisForm(true);
            setSelectedDevis(null);
            setDEvisObject(null)
            setDestinataire("");
        } else {
            setShowSansDevisForm(false);
            setDEvisObject(devisId);
            try {
                const response = await axios.get(`${DEVIS_URL}/${devisId}`);
                setSelectedDevis(response?.data);
                calculateTotals(response?.data.devisItems);
            } catch (err) {
                console.error("Error fetching devis details", err);
                setSelectedDevis(null);
            }
        }
    };
    
    const formatDevisNumber = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).replace(/[^\d]/g, '');
    };

    const calculateTotals = (devisItems) => {
        const totalHT = devisItems.reduce((total, item) => total + (item.qte * item.price), 0);
        const tvaAmount = (totalHT * 0.20).toFixed(2);
        const montantTTC = (totalHT + parseFloat(tvaAmount)).toFixed(2);

        setTotalHt(totalHT);
        setTva(tvaAmount);
        setMttc(montantTTC);
    };

    const handleAddItem = () => {
        setItems([...items, { designation: "", qte: 1, price: 0 }]);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                devisId: devisObject,
                entrepriseId: destinataire,
                montant: mttc,
                devise: devise,
                items: items,
                modeDePaiement,
            };

            await axios.post(ADD_FACTURE_URL, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' },
            });

            setItems([{ designation: "", qte: 1, price: 0 }]);
            setDestinataire('');
            setSelectedDevis(null);
            setSuccMsg("Facture Added");
            navigate("/billing");
        } catch (err) {
            setErrMsg("Error creating facture.");
        }
    };

    return (
        <div>
            <Sidebar />
            <div className="min-content">
                <Navbar />
                <div className="cotent-container min-h-screen flex items-center justify-center py-12 px-4">
                    <div className="w-full max-w-4xl">
                        <form
                            className="bg-white p-8 ml-5 rounded-xl shadow-lg border border-gray-100"
                            onSubmit={handleSubmit}
                        >
                            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#8739ef] to-[#a56eff]">
                                    Nouveau Facture
                                </span>
                            </h2>

                            {errMsg && (
                                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-md" role="alert">
                                    <p className="text-red-700">{errMsg}</p>
                                </div>
                            )}

                            {succMsg && (
                                <div ref={succRef} className="bg-green-50 border-l-4 border-green-400 p-4 mb-6 rounded-md">
                                    <p className="text-green-700">{succMsg}</p>
                                </div>
                            )}

                            <div className="form-group">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">Select Devis</label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    onChange={(e) => handleSelectDevis(e.target.value)}
                                    value={devisObject}
                                    required
                                >
                                    <option value="" disabled>Select Devis</option>
                                    <option value="sansDevis">Créer Facture Sans Devis</option>
                                    {devis.map((d) => (
                                        <option key={d._id} value={d._id}>
                                            {d.entreprise}/{d.date && formatDevisNumber(d.date)}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {showSansDevisForm ? (
                                <div>
                        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#8739ef] to-[#a56eff]">
                            </span>
                        </h2>

                        {errMsg && (
                            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-md" role="alert">
                                <p className="text-red-700">{errMsg}</p>
                            </div>
                        )}

                        {succMsg && (
                            <div ref={succRef} className="bg-green-50 border-l-4 border-green-400 p-4 mb-6 rounded-md">
                                <p className="text-green-700">{succMsg}</p>
                            </div>
                        )}

                        <div className="space-y-6">
                            <div className="form-group">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Destinataire
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    onChange={(e) => setDestinataire(e.target.value)}
                                    value={destinataire}
                                >
                                    <option value="" disabled>Choisir Destinataire</option>
                                    {clients.map((client) => (
                                        <option key={client._id} value={client._id}>
                                            {client.entreprise}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Devise
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    onChange={(e) => setDevise(e.target.value)}
                                    value={devise}
                                    required
                                >
                                    <option value="MAD">Dirham MAD</option>
                                    <option value="EUR">Euro (€)</option>
                                    <option value="USD">Dollar ($)</option>
                                </select>
                            </div>

                            <div className="form-group mt-6">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Mode de Paiement
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    value={modeDePaiement}
                                    onChange={(e) => setModeDePaiement(e.target.value)}
                                >
                                    <option value="VIREMENT">VIREMENT</option>
                                    <option value="CHÈQUE">CHÈQUE</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <h3 className="text-xl font-semibold mb-4 text-gray-700">Items</h3>
                                <div className="space-y-4">
                                    {items.map((item, index) => (
                                        <div key={index} className="p-4 bg-gray-50 rounded-lg border border-gray-100">
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                                <input
                                                    type="text"
                                                    placeholder="Designation"
                                                    className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                                    value={item.designation}
                                                    onChange={(e) => handleItemChange(index, "designation", e.target.value)}
                                                    required
                                                />
                                                <input
                                                    type="number"
                                                    min="1"
                                                    placeholder="Quantity"
                                                    className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                                    value={item.qte || ''}
                                                    onChange={(e) => handleItemChange(index, "qte", +e.target.value)}
                                                    required
                                                />
                                                <input
                                                    type="number"
                                                    min="1"
                                                    step="1"
                                                    placeholder="Price"
                                                    className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                                    value={item.price || ''}
                                                    onChange={(e) => handleItemChange(index, "price", +e.target.value)}
                                                    required
                                                />
                                            </div>
                                            {items.length > 1 && (
                                                <button
                                                    type="button"
                                                    className="mt-3 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
                                                    onClick={() => handleRemoveItem(index)}
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <button
                                    type="button"
                                    className="mt-4 px-6 py-3 bg-[#8739ef] text-white rounded-lg hover:bg-[#7029d6] transition-colors duration-200 flex items-center gap-2"
                                    onClick={handleAddItem}
                                >
                                    <span className="text-xl">+</span>
                                    Add Item
                                </button>
                            </div>

                            <div className="bg-gray-50 p-6 rounded-xl border border-gray-100 space-y-3">
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Total HT:</span>
                                    <span className="font-semibold text-gray-800">{totalHt.toFixed(2)} {devise}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">TVA (20%):</span>
                                    <span className="font-semibold text-gray-800">{tva} {devise}</span>
                                </div>
                                <div className="flex justify-between items-center pt-3 border-t border-gray-200">
                                    <span className="text-gray-700 font-bold">Montant Total TTC:</span>
                                    <span className="font-bold text-[#8739ef] text-xl">{mttc} {devise}</span>
                                </div>
                            </div>
                        </div>
                                </div>
                            ) : (
                                selectedDevis && (
                                    <div>
                                        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#8739ef] to-[#a56eff]">
                                </span>
                            </h2>

                            {errMsg && (
                                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-md" role="alert">
                                    <p className="text-red-700">{errMsg}</p>
                                </div>
                            )}

                            {succMsg && (
                                <div ref={succRef} className="bg-green-50 border-l-4 border-green-400 p-4 mb-6 rounded-md">
                                    <p className="text-green-700">{succMsg}</p>
                                </div>
                            )}

                            <div className="form-group mt-6">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Mode de Paiement
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    value={modeDePaiement}
                                    onChange={(e) => setModeDePaiement(e.target.value)}
                                >
                                    <option value="VIREMENT">VIREMENT</option>
                                    <option value="CHÈQUE">CHÈQUE</option>
                                </select>
                            </div>

                            {selectedDevis && (
                                <div className="mt-8">
                                    <h3 className="text-xl font-semibold mb-4">Devis Details</h3>
                                    <div className="mb-6">
                                        <p><strong>Client:</strong> {selectedDevis.entreprise || 'Not Found'}</p>
                                        <p><strong>Total:</strong> {selectedDevis.devisMontant} {selectedDevis.devise}</p>
                                    </div>
                                    <table className="table-auto w-full text-left border-collapse border border-gray-200">
                                        <thead>
                                            <tr>
                                                <th className="border border-gray-300 px-4 py-2">Designation</th>
                                                <th className="border border-gray-300 px-4 py-2">Quantity</th>
                                                <th className="border border-gray-300 px-4 py-2">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedDevis.devisItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="border border-gray-300 px-4 py-2">{item.designation}</td>
                                                    <td className="border border-gray-300 px-4 py-2">{item.qte}</td>
                                                    <td className="border border-gray-300 px-4 py-2">{item.price}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="bg-gray-50 p-6 rounded-xl border border-gray-100 space-y-3 mt-6">
                                        <div className="flex justify-between items-center">
                                            <span className="text-gray-600">Total HT:</span>
                                            <span className="font-semibold text-gray-800">{totalHt.toFixed(2)} {devise}</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-gray-600">TVA (20%):</span>
                                            <span className="font-semibold text-gray-800">{tva} {devise}</span>
                                        </div>
                                        <div className="flex justify-between items-center pt-3 border-t border-gray-200">
                                            <span className="text-gray-700 font-bold">Montant Total TTC:</span>
                                            <span className="font-bold text-[#8739ef] text-xl">{mttc} {devise}</span>
                                        </div>
                                    </div>
                                </div>
                            )}

                                    </div>
                                )
                            )}

                            <button
                                type="submit"
                                className="w-full py-4 bg-[#8739ef] text-white rounded-xl hover:bg-[#7029d6] transition-all duration-200 font-semibold text-lg shadow-lg shadow-[#8739ef]/30 mt-8"
                            >
                                Submit Facture
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddFacture;
