import React from "react";

const Modal = ({ show, onClose, onSubmit, children }) => {
    if (!show) return null;

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                onSuccess: () => {
                    if (onSubmit) onSubmit();
                    onClose();
                }
            });
        }
        return child;
    });

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-[500px] h-[500px] rounded-lg shadow-lg relative p-6 flex flex-col justify-center items-center">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-2xl font-bold hover:text-gray-700"
                    aria-label="Close modal"
                >
                    &times;
                </button>
                {childrenWithProps}
            </div>
        </div>
    );
};

export default Modal;