import axios from "../../../api/axios";
import {useEffect, useState} from "react";
import "../DisplayClients/DisplayClients.css";
import {FaPlus, FaRegEye} from "react-icons/fa";
import {RiDeleteBin2Line} from "react-icons/ri";
import {Link, useNavigate} from "react-router-dom";
import {IoSettingsOutline} from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TbSettingsOff } from "react-icons/tb";
import { GrMoney } from "react-icons/gr";

const GET_FACTURE_URL = '/api/facture/details'
const DELETE_FACTURE_URL = '/api/facture'


function DisplayFacture() {
    const [facture, setFacture] = useState([]);
    const navigate = useNavigate();

    async function deleteClient(id) {
        try {
            const userConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette facture ?");
            if (userConfirmed) {
                await axios.delete(`${DELETE_FACTURE_URL}/${id}`);
                OnInit();
            } else {
                console.log("Suppression annulée");
            }
        } catch (err) {
            console.error("Error deleting Facture:", err);
        }
    }


    const OnInit = async () => {

        try {
            const response = await axios.get(GET_FACTURE_URL);
            setFacture(response?.data);
        } catch (err) {
            console.error("Error getting data:", err);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    useEffect(() => {
        OnInit();
    }, []);

    const toggleStatus = async (id) => {
        try {
            const response = await axios.put(`/api/facture/status/${id}`);
            OnInit();
        } catch (error) {
            console.error("Error toggling status:", error.message);
        }
    };

    return (

        <div className="main-content">
            <Link to="/add-facture" type="button" className='addBtn'>
                <FaPlus color="white" />
            </Link>
            <div className="overflow-x-auto shadow-md sm:rounded-lg ">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead
                        className="text-xs text-gray-700 uppercase bg-light dark:bg-gray-700 dark:text-white">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Entreprise
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Date De Creation
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Montant HT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Montant TTC
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Mode De Paiement
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        facture.map((item, index) => {
                            const items = item.devisItems?.length > 0 ? item.devisItems : item.factureItems;
                            const montantHT = items.reduce((total, currentItem) => total + (currentItem.qte * currentItem.price), 0);
                            
                            return  <tr key={index}>
                            <td className="px-6 py-4">{item.factureId}</td>
                            <td className="px-6 py-4">{item.entreprise}</td>
                            <td className="px-6 py-4">{formatDate(item.factureDate)}</td>
                            <td className="px-6 py-4">{montantHT.toFixed(2)}</td>
                            <td className="px-6 py-4">
                                {(item.devisMontant !== 0 ? item.devisMontant : item.factureMontant).toFixed(2)}
                            </td>
                            <td className="px-6 py-4">{item.modeDePaiement}</td>
                            <td
                                className={`px-6 py-4 font-semibold ${
                                    item.status === "En cours"
                                        ? "text-amber-400"
                                        : item.status === "Encaisser"
                                        ? "text-green-500"
                                        : item.status === "No payer"
                                        ? "text-red-500"
                                        : ""
                                }`}
                            >
                                {item.status}
                            </td>
                            <td className="action px-6 py-4">
                            <GrMoney  
                                 onClick={() => toggleStatus(item.factureId)} 
                                className="cursor-pointer" 
                                color={item.status === "Encaisser" ? "green" : "#FAB12F"} 
                                    />
                            <FaRegEye
                                    color="#1e40af"
                                    onClick={() => navigate(`/facture/${item?.factureId}`)}
                                />
                                {
                                facture && item.devisId === "not found" ? (
                                    <IoSettingsOutline
                                    color="green"
                                    onClick={() => navigate(`/facture/edit/${item?.factureId}`)}
                                    />
                                ) : (
                                    <TbSettingsOff
                                    color="red"
                                    onClick={() => console.log('Devis found, editing disabled')}
                                    />
                                )
                                }

                            <RiDeleteBin2Line
                                    color="red"
                                    onClick={() => deleteClient(item?.factureId)}
                                />
                                
                            </td>
                        </tr>
                        })
                    }
                    </tbody>

                </table>
            </div>
        </div>
    );
}

export default DisplayFacture;
