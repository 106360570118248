import React, { useState } from "react";
import { RiPieChart2Fill, RiShoppingBag4Line } from "react-icons/ri";
import { HiOutlineLogout } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import groupImage from "../../assets/Group.png";
import './Sidebar.css';

const Sidebar = () => {
    const [open, setOpen] = useState(window.innerWidth > 768);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("selectedTab");

        navigate("/login");
    };

    return (
        <section className="sidebar">
            <button className="menu-toggle-button" onClick={toggleSidebar}>
                <AiOutlineMenu size={24} />
            </button>

            <div className={`sidebar-container ${open ? 'open' : 'closed'}`}>
                <div className='head'>
                    <img src={groupImage} alt='Gentium.io' className='head-img'/>
                    <h2 className={`titre ${!open ? 'hidden' : ''}`}>Gentium.io</h2>
                </div>
                <div className="logo-container">
                    <Link to="/billing" className={`logo-button ${open ? 'active' : ''}`}>
                        <RiPieChart2Fill size={22}/>
                        <h2 className={`logo-text ${!open ? 'hidden' : ''}`}>Gentium.io</h2>
                    </Link>
                </div>
                <div className="menu-container">

                    <Link to="/billing" className="menu-item">
                        <div className="icon-container">
                            <RiShoppingBag4Line size={26}/>
                        </div>
                        <h2 className={`menu-text ${!open ? 'hidden' : ''}`}>Billing</h2>
                    </Link>

                    <Link onClick={handleLogout} className="menu-item">
                        <div className="icon-container">
                            <HiOutlineLogout size={26}/>
                        </div>
                        <h2 className={`menu-text ${!open ? 'hidden' : ''}`}>Sign out</h2>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Sidebar;
