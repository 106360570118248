import {Route, Routes, Navigate} from "react-router-dom";
import "./App.css";
import Login from "./components/Login/Login";
import Unauthorized from "./components/Unauthorized/unauthorized";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import Billing from "./components/Billing/Billing";
import DisplayClients from "./components/Billing/DisplayClients/DisplayClients";
import AddClient from "./components/Billing/AddClient/AddClient";
import EditClient from "./components/Billing/EditClient/EditClient";
import AddDevis from "./components/Billing/AddDevis/AddDevis";
import ShowDevis from "./components/Billing/ShowDevis/ShowDevis"; // Import the CSS file
import EditDevis from "./components/Billing/EditDevis/EditDevis";
import ShowFacture from "./components/Billing/Facture/ShowFacture";
import AddFacture from "./components/Billing/Facture/AddFacture";
import EditFacture from "./components/Billing/Facture/EditFacture";

const App = () => {
    return (
            <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path='/login' element={<Login />}/>
                <Route path='/unauthorized' element={<Unauthorized/>}/>

                <Route path='billing' element={
                    <ProtectedRoute roles={['admin']}>
                        <Billing/>
                    </ProtectedRoute>
                }></Route>

                <Route path='clients' element={
                    <ProtectedRoute roles={['admin']}>
                        <DisplayClients/>
                    </ProtectedRoute>
                }></Route>

                <Route path='clients/:id' element={
                    <ProtectedRoute roles={['admin']}>
                        <EditClient/>
                    </ProtectedRoute>
                }></Route>

                <Route path='add-client' element={
                    <ProtectedRoute roles={['admin']}>
                        <AddClient/>
                    </ProtectedRoute>
                }></Route>

                <Route path='add-devis' element={
                    <ProtectedRoute roles={['admin']}>
                        <AddDevis/>
                    </ProtectedRoute>
                }></Route>

                <Route path='devis/:id' element={
                    <ProtectedRoute roles={['admin']}>
                        <ShowDevis/>
                    </ProtectedRoute>
                }></Route>

                <Route path='devis/edit/:id' element={
                    <ProtectedRoute roles={['admin']}>
                        <EditDevis/>
                    </ProtectedRoute>
                }></Route>

                <Route path='facture/:id' element={
                    <ProtectedRoute roles={['admin']}>
                        <ShowFacture/>
                    </ProtectedRoute>
                }></Route>

                <Route path='add-facture' element={
                    <ProtectedRoute roles={['admin']}>
                        <AddFacture/>
                    </ProtectedRoute>
                }></Route>

                <Route path='facture/edit/:id' element={
                    <ProtectedRoute roles={['admin']}>
                        <EditFacture/>
                    </ProtectedRoute>
                }></Route>

            </Routes>
    );
};

export default App;
