import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { LuPhone, LuMapPin } from "react-icons/lu";
import { MdOutlineMailOutline } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";
import { FaRegBuilding } from "react-icons/fa";
import { MdOutlineConfirmationNumber } from "react-icons/md";

const ADD_CLIENT_URL = "/api/client/add";

function AddClient({ onSuccess }) {
    const [entreprise, setEnteprise] = useState("");
    const [ice, setIce] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [siteInternet, setSiteInternet] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [fieldErrors, setFieldErrors] = useState({});

    const validateFields = () => {
        const errors = {};
        if (!entreprise.trim()) errors.entreprise = "Le champ entreprise est obligatoire.";
        if (!ice.trim()) errors.ice = "Le champ ICE est obligatoire.";
        if (!adresse.trim()) errors.adresse = "Le champ adresse est obligatoire.";
        if (email && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
            errors.email = "Veuillez entrer une adresse email valide.";
        }
        if (siteInternet && !/^(https?:\/\/)?([\w-]+\.)+[\w-]{2,3}(\.[a-zA-Z]{2,})?$/.test(siteInternet)) {
            errors.siteInternet = "Veuillez entrer une URL valide.";
        }
        return errors;
    };

    useEffect(() => {
        setErrMsg("");
    }, [entreprise, ice, adresse, telephone, email, siteInternet]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return;
        }

        try {
            await axios.post(
                ADD_CLIENT_URL,
                JSON.stringify({ entreprise, ice, adresse, telephone, email, siteInternet }),
                { headers: { "Content-Type": "application/json" } }
            );

            setEnteprise("");
            setIce("");
            setAdresse("");
            setTelephone("");
            setEmail("");
            setSiteInternet("");
            setErrMsg("");
            setFieldErrors({});

            if (onSuccess) onSuccess();
        } catch (err) {
            if (!err.response) {
                setErrMsg("Aucune réponse du serveur.");
            } else if (err.response.status === 400) {
                setErrMsg(err.response.data.message || "Requête invalide.");
            } else if (err.response.status === 500) {
                setErrMsg("L'email est déjà utilisé.");
            } else {
                setErrMsg("Une erreur est survenue. Veuillez réessayer.");
            }
        }
    };

    return (
        <div>
            <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
                {errMsg && (
                    <p className="text-red-600 mb-4" aria-live="assertive">
                        {errMsg}
                    </p>
                )}
                <div className="relative z-0 w-full mb-5 group">
                    <input
                        type="text"
                        name="floating_email"
                        id="floating_email"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => setEnteprise(e.target.value)}
                        value={entreprise}
                        placeholder=""
                    />
                    <label
                        htmlFor="floating_email"
                        className="flex items-center peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                       <FaRegBuilding /> Entreprise
                    </label>
                    {fieldErrors.entreprise && <p className="text-red-600 text-sm">{fieldErrors.entreprise}</p>}
                </div>
                <div className="relative z-0 w-full mb-5 group">
                    <input
                        type="number"
                        name="floating_password"
                        id="floating_password"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => setIce(e.target.value)}
                        value={ice}
                        placeholder=" "
                    />
                    <label
                        htmlFor="floating_password"
                        className="flex items-center peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                       <MdOutlineConfirmationNumber /> ICE
                    </label>
                    {fieldErrors.ice && <p className="text-red-600 text-sm">{fieldErrors.ice}</p>}
                </div>
                <div className="relative z-0 w-full mb-5 group">
                    <input
                        type="text"
                        name="floating_password"
                        id="floating_password"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => setAdresse(e.target.value)}
                        value={adresse}
                        placeholder=" "
                    />
                    <label
                        htmlFor="floating_password"
                        className="flex items-center peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                        <LuMapPin /> Adresse
                    </label>
                    {fieldErrors.adresse && <p className="text-red-600 text-sm">{fieldErrors.adresse}</p>}
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="tel" name="floating_phone"
                                       id="floating_phone"
                                       className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       onChange={(e) => setTelephone(e.target.value)}
                                       value={telephone}
                                       placeholder=" "/>
                                <label htmlFor="floating_phone"
                                       className="flex items-center peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"><LuPhone /> Telephone</label>
                            
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="email" name="floating_company" id="floating_company"
                                       className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       onChange={(e) => setEmail(e.target.value)}
                                       value={email}
                                       placeholder=" "/>
                                <label htmlFor="floating_company"
                                       className="flex items-center peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"><MdOutlineMailOutline /> Email
                                    </label>
                                    {fieldErrors.email && <p className="text-red-600 text-sm">{fieldErrors.email}</p>}
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="text" name="floating_company" id="floating_company"
                                       className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                       onChange={(e) => setSiteInternet(e.target.value)}
                                       value={siteInternet}
                                       placeholder=" "/>
                                <label htmlFor="floating_company"
                                       className="flex items-center peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"><TfiWorld /> Site
                                    Internet
                                </label>
                                {fieldErrors.siteInternet && <p className="text-red-600 text-sm">{fieldErrors.siteInternet}</p>}
                            </div>
                        </div>
                <button
                    type="submit"
                    className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-purple-800 dark:hover:bg-purple-900 dark:focus:ring-purple-800"
                >
                    Submit
                </button>
            </form>
        </div>
    );
}

export default AddClient;
