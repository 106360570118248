import './Navbar.css';
import React from 'react';

const Navbar = () => {
    return (

        <nav className="bg-white border-gray-200">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <h1 className='self-center text-2xl font-semibold whitespace-nowrap'>Dashboard</h1>
                </div>
        </nav>
);
};

export default Navbar;
