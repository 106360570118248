import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "../../../api/axios";
import Sidebar from "../../Sidebar/Sidebar";
import Navbar from "../../Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";


const EDIT_FACTURE_URL = '/api/facture';
const GET_FACTURE_URL = '/api/facture/details';
const CLIENTS_URL = '/api/client';

function EditFacture() {
    const succRef = useRef();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [items, setItems] = useState([{ designation: "", qte: 1, price: 0 }]);
    const [destinataire, setDestinataire] = useState("");
    const [devise, setDevise] = useState("MAD");
    const [totalHt, setTotalHt] = useState(0);
    const [tva, setTva] = useState(0);
    const [mttc, setMttc] = useState(0);
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const [modeDePaiement, setModeDePaiement] = useState("VIREMENT");

    const id = useParams().id;

    useEffect(() => {
        setErrMsg("");
    }, [destinataire, items]);

    const OnInit = async () => {
        try {
            const facture = await axios.get(`${GET_FACTURE_URL}/${id}`);

            setDestinataire(facture.data.clientId || "");
            setDevise(facture.data.devise || "MAD");
            setItems(facture.data.devisItems && facture.data.devisItems.length > 0 ? facture.data.devisItems : facture.data.factureItems);
            setTotalHt(facture.data.devisMontant || 0);
            setModeDePaiement(facture.data.modeDePaiement);
    
            const { data: clients } = await axios.get(CLIENTS_URL);
            setClients(clients);
        } catch (err) {
            console.error("Error fetching data", err);
            setErrMsg("Failed to load data. Please try again.");
        }
    };
    

    useEffect(() => {
        OnInit();
    }, []);

    const handleAddItem = () => {
        setItems([...items, { designation: "", qte: 1, price: 0 }]);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const calculateTotalHt = () => {
        return items.reduce((total, item) => total + (item.qte * item.price), 0);
    };

    const calculateTva = (total) => {
        return (total * 0.20).toFixed(2);
    };

    const calculateMttc = (total, tvaAmount) => {
        return (total + parseFloat(tvaAmount)).toFixed(2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        for (const item of items) {
            if (!item.designation || item.designation.trim() === "") {
                setErrMsg("All items must have a designation.");
                return;
            }
        }

        try {
            const response = await axios.put(`${EDIT_FACTURE_URL}/${id}`,
                JSON.stringify({
                    entrepriseId: destinataire,
                    montant: mttc,
                    items: items,
                    devise,
                    modeDePaiement,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            setItems([{ designation: "", qte: 1, price: 0 }]);
            setDestinataire('');
            setSuccMsg("Devis Added");

            navigate("/billing");

        } catch (err) {
            setErrMsg("Error creating devis.");
        }
    };

    useEffect(() => {
        console.log("Devise value:", devise); 
    }, [devise]);
    

    useEffect(() => {
        const newTotalHt = calculateTotalHt();
        const newTva = calculateTva(newTotalHt);
        const newMttc = calculateMttc(newTotalHt, newTva);

        setTotalHt(newTotalHt);
        setTva(newTva);
        setMttc(newMttc);
    }, [items]);

    return (
        <div>
            <Sidebar />
           <div className="min-content">
                <Navbar />
                <div className="cotent-container min-h-screen flex items-center justify-center py-12 px-4">
                  <div className="w-full max-w-4xl">
                    <form
                        className="bg-white p-8 ml-5 rounded-xl shadow-lg border border-gray-100"
                        onSubmit={handleSubmit}
                    >
                        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#8739ef] to-[#a56eff]">
                                Modifier Facture
                            </span>
                        </h2>

                        {errMsg && (
                            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-md" role="alert">
                                <p className="text-red-700">{errMsg}</p>
                            </div>
                        )}

                        {succMsg && (
                            <div ref={succRef} className="bg-green-50 border-l-4 border-green-400 p-4 mb-6 rounded-md">
                                <p className="text-green-700">{succMsg}</p>
                            </div>
                        )}

                        <div className="space-y-6">
                            <div className="form-group">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Destinataire
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    onChange={(e) => setDestinataire(e.target.value)}
                                    value={destinataire}
                                    required
                                >
                                    <option value="" disabled>Choisir Destinataire</option>
                                    {clients.map((client) => (
                                        <option key={client._id} value={client._id}>
                                            {client.entreprise}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Devise
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    onChange={(e) => setDevise(e.target.value)}
                                    value={devise}
                                    required
                                >
                                    <option value="MAD">Dirham MAD</option>
                                    <option value="EUR">Euro (€)</option>
                                    <option value="USD">Dollar ($)</option>
                                </select>
                            </div>

                            <div className="form-group mt-6">
                                <label className="block text-gray-700 text-sm font-semibold mb-2">
                                    Mode de Paiement
                                </label>
                                <select
                                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                    value={modeDePaiement}
                                    onChange={(e) => setModeDePaiement(e.target.value)}
                                >
                                    <option value="VIREMENT">VIREMENT</option>
                                    <option value="CHÈQUE">CHÈQUE</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <h3 className="text-xl font-semibold mb-4 text-gray-700">Items</h3>
                                <div className="space-y-4">
                                    {items.map((item, index) => (
                                        <div key={index} className="p-4 bg-gray-50 rounded-lg border border-gray-100">
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                                <input
                                                    type="text"
                                                    placeholder="Designation"
                                                    className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                                    value={item.designation}
                                                    onChange={(e) => handleItemChange(index, "designation", e.target.value)}
                                                    required
                                                />
                                                <input
                                                    type="number"
                                                    min="1"
                                                    placeholder="Quantity"
                                                    className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                                    value={item.qte || ''}
                                                    onChange={(e) => handleItemChange(index, "qte", +e.target.value)}
                                                    required
                                                />
                                                <input
                                                    type="number"
                                                    min="1"
                                                    step="1"
                                                    placeholder="Price"
                                                    className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8739ef] focus:border-transparent transition-all duration-200 bg-white text-gray-700"
                                                    value={item.price || ''}
                                                    onChange={(e) => handleItemChange(index, "price", +e.target.value)}
                                                    required
                                                />
                                            </div>
                                            {items.length > 1 && (
                                                <button
                                                    type="button"
                                                    className="mt-3 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200"
                                                    onClick={() => handleRemoveItem(index)}
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <button
                                    type="button"
                                    className="mt-4 px-6 py-3 bg-[#8739ef] text-white rounded-lg hover:bg-[#7029d6] transition-colors duration-200 flex items-center gap-2"
                                    onClick={handleAddItem}
                                >
                                    <span className="text-xl">+</span>
                                    Add Item
                                </button>
                            </div>

                            <div className="bg-gray-50 p-6 rounded-xl border border-gray-100 space-y-3">
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">Total HT:</span>
                                    <span className="font-semibold text-gray-800">{totalHt.toFixed(2)} {devise}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-gray-600">TVA (20%):</span>
                                    <span className="font-semibold text-gray-800">{tva} {devise}</span>
                                </div>
                                <div className="flex justify-between items-center pt-3 border-t border-gray-200">
                                    <span className="text-gray-700 font-bold">Montant Total TTC:</span>
                                    <span className="font-bold text-[#8739ef] text-xl">{mttc} {devise}</span>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="w-full py-4 bg-[#8739ef] text-white rounded-xl hover:bg-[#7029d6] transition-all duration-200 font-semibold text-lg shadow-lg shadow-[#8739ef]/30"
                            >
                                Submit Devis
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    );
}

export default EditFacture;