import { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import "./Billing.css";
import DisplayClients from "./DisplayClients/DisplayClients";
import DisplayDevis from "./DisplayDevis/DisplayDevis";
import DisplayFacture from "./Facture/DisplayFacture";

function Billing() {
    const [toggle, setToggle] = useState(1);

    useEffect(() => {
        const savedTab = localStorage.getItem("selectedTab");
        if (savedTab) {
            setToggle(Number(savedTab));
        }
    }, []);

    const updateToggle = (id) => {
        setToggle(id);
        localStorage.setItem("selectedTab", id);
    };

    return (
        <div>
            <Sidebar/>

            <div className="main-content">
                <Navbar/>
                <div className="content-container">

                    <ul className="flex flex-wrap text-sm font-medium text-center border-b border-gray-200 dark:border-gray-700">
                        <li
                            className={`cursor-pointer ${toggle === 1 ? 'bg-gray-800 text-white' : 'text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'} px-4 py-2 rounded-t-lg transition-all duration-200 ease-in-out`}
                            onClick={() => updateToggle(1)}
                        >
                            Client
                        </li>
                        <li
                            className={`cursor-pointer ${toggle === 2 ? 'bg-gray-800 text-white' : 'text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'} px-4 py-2 rounded-t-lg transition-all duration-200 ease-in-out`}
                            onClick={() => updateToggle(2)}
                        >
                            Devis
                        </li>
                        <li
                            className={`cursor-pointer ${toggle === 3 ? 'bg-gray-800 text-white' : 'text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'} px-4 py-2 rounded-t-lg transition-all duration-200 ease-in-out`}
                            onClick={() => updateToggle(3)}
                        >
                            Facturation
                        </li>
                    </ul>

                    <div className={toggle === 1 ? "show-content" : "content"}>
                        <DisplayClients />
                    </div>

                    <div className={toggle === 2 ? "show-content" : "content"}>
                        <DisplayDevis />
                    </div>

                    <div className={toggle === 3 ? "show-content" : "content"}>
                        <DisplayFacture />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Billing;
