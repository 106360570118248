import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { useParams } from "react-router-dom";
import gentiumLogo from "../../../assets/gentuim.png";
import "./ShowFacture.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import n2words from "n2words";

const GET_FACTURE_URL = "/api/facture/details";

const ShowFacture = () => {
  const [devis, setFacture] = useState([]);
  const [totalHT, setTotalHT] = useState(0);
  const id = useParams().id;

  useEffect(() => {
    OnInit();
  }, []);

  const OnInit = async () => {
    try {
      const response = await axios.get(`${GET_FACTURE_URL}/${id}`);
      setFacture(response?.data);
      calculateTotal(response?.data.devisItems, response?.data.factureItems);
    } catch (err) {
      console.error("Error getting data:", err);
    }
  };

  const calculateTotal = (devisItems, factureItems) => {
    const items = devisItems?.length > 0 ? devisItems : factureItems;
    const total = items?.reduce((acc, item) => acc + item.price * item.qte, 0);
    setTotalHT(total);
  };

  const formatPrice = (price) => {
    return `${price.toFixed(2)} ${devis.devise || "MAD"}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR");
  };

  const formatDevisNumber = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/[^\d]/g, "");
  };

  const downloadPDF = async () => {
    const element = document.getElementById("pdf-content");

    element.style.minHeight = "100%";
    element.style.position = "relative";

    const canvas = await html2canvas(element, {
      scale: 3,
      useCORS: true,
      backgroundColor: "#ffffff",
    });

    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    element.style.minHeight = null;
    element.style.position = null;

    pdf.save(`facture-${id}.pdf`);
  };
  const getTotalInWords = (amount) => {
    if (!amount || isNaN(amount)) return "";

    const words = n2words(amount, { lang: "fr" });
    return (
      words.charAt(0).toUpperCase() +
      words.slice(1) +
      ` ${devis.devise || "dirhams"}`
    );
  };

  return (
    <div>
      <div id="pdf-content" className="max-w-5xl mx-auto bg-white shadow-lg">
        <div className="top-strip"></div>
        <div className="infos grid grid-cols-2 gap-8 mb-12">
          <div>
            <img src={gentiumLogo} alt="Gentium" className="mb-4 w-32 h-32" />
            <div className="text-sm text-gray-600">
              <p className="entreprise text-2xl uppercase mb-4">GENTIUM.IO</p>
              <p>
                <span className="client font-semibold">ICE :</span>{" "}
                003239590000044
              </p>
              <p>
                <span className="client font-semibold">Contact :</span> SOUBBA
                ILYASSE
              </p>
              <p>
                <span className="client font-semibold">Adresse :</span> Tr 01
                lettre Q n° 134 Tamenssourt,
                <br /> Marrakech
              </p>
              <p>
                <span className="client font-semibold">Téléphone :</span> +212 6
                63 52 56 60
              </p>
              <p>
                <span className="client font-semibold">Email :</span>{" "}
                contact@gentium.io
              </p>
              <p>
                <span className="client font-semibold">Site web :</span>{" "}
                www.gentium.io
              </p>
            </div>
          </div>

          <div className="text-sm">
            <div className="text-gray-600">
              <h1 className="entreprise text-2xl tracking-widest mb-2">
                F A C T U R E
              </h1>
              <p className="text-xl tracking-widest text-gray-800">
                <span className="entreprise tracking-widest">Date :</span>{" "}
                {devis.date && formatDate(devis.date)}
              </p>
              <p className="text-xl tracking-widest text-gray-800">
                <span className="entreprise tracking-widest">
                  N° de facture :
                </span>{" "}
                FA-{devis.date && formatDevisNumber(devis.date)}
              </p>
              {devis.date && devis.devisDevise !== "not found" && (
                <p className="text-xl tracking-widest text-gray-800">
                  <span className="entreprise tracking-widest">
                    Liée au devis N° :
                  </span>{" "}
                  {formatDevisNumber(devis.dateDevis)}
                </p>
              )}
            </div>

            <div className="flex-wrap gap-x-8 text-sm text-gray-600 mb-5">
              <p className="devs text-2xl uppercase mb-2">{devis.entreprise}</p>
              <p>
                <span className="client font-semibold">ICE :</span> {devis.ice}
              </p>
              <p>
                <span className="client font-semibold">Téléphone :</span>{" "}
                {devis.phone}
              </p>
              <p>
                <span className="client font-semibold">Adresse :</span>{" "}
                {devis.adresse}
              </p>
              <p>
                <span className="client font-semibold">Email :</span>{" "}
                {devis.email}
              </p>
              <p>
                <span className="client font-semibold">Site web :</span>{" "}
                {devis.site}
              </p>
            </div>
            <p className="mode-payaiment text-base">
              <span className="payement text-xl"> MODE DE PAIEMENT : </span>{" "}
              {devis.modeDePaiement}
            </p>
          </div>
        </div>
        <div className="mb-12">
          <div className="table-container mx-auto max-w-4xl">
            <table className="w-full text-sm">
              <thead className="table-header">
                <tr className="table-header">
                  <th className="p-6 text-left font-semibold">Désignation</th>
                  <th className="p-6 text-center font-semibold w-24">Qté</th>
                  <th className="p-6 text-right font-semibold w-40">PU HT</th>
                  <th className="p-6 text-right font-semibold w-40">
                    TOTAL HT
                  </th>
                </tr>
              </thead>
              <tbody>
                {(devis.devisItems?.length > 0
                  ? devis.devisItems
                  : devis.factureItems
                )?.map((item) => (
                  <tr key={item._id}>
                    <td className="border border-gray-300 p-5">
                      {item.designation}
                    </td>
                    <td className="border border-gray-300 p-5 text-center">
                      {item.qte}
                    </td>
                    <td className="border border-gray-300 p-5 text-right">
                      {formatPrice(item.price)}
                    </td>
                    <td className="border border-gray-300 p-5 text-right">
                      {formatPrice(item.price * item.qte)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end mb-12 mr-20">
          <div className="w-72 space-y-2 text-sm">
            <div className="flex justify-between p-2 border-b">
              <span>Total HT</span>
              <span>{formatPrice(totalHT)}</span>
            </div>
            <div className="flex justify-between p-2 border-b">
              <span>TVA (20%)</span>
              <span>{formatPrice(totalHT * 0.2)}</span>
            </div>
            <div className="flex justify-between p-2 border-b bg-gray-50">
              <span className="prices font-bold">MONTANT TOTAL TTC</span>
              <span className="prices font-bold">
                {formatPrice(totalHT * 1.2)}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 ms-20 text-gray-700 uppercase">
          <span className="font-bold">
            Arrêté la présente facture à la somme de:{" "}
          </span>
          {getTotalInWords(totalHT * 1.2)}
        </div>

        <hr className="hr" />

        <div className="infos flex flex-wrap gap-8 text-sm mb-12">
          <div>
            <h3 className="prices font-bold mb-2 uppercase">Questions ?</h3>
            <div className="text-gray-600 space-y-1">
              <p>ÉCRIVEZ-NOUS : contact@gentium.io</p>
              <p>APPELEZ-NOUS : +212 6 63 52 56 60</p>
            </div>
          </div>

          <div>
            <h3 className="prices font-bold mb-2 uppercase">
              Informations de paiement
            </h3>
            <div className="text-gray-600 space-y-1">
              <p>BANQUE : BANK OF AFRICA</p>
              <p>NOM DU COMPTE : GENTIUM.IO</p>
              <p>RIB : 011 450 0000072100009440 15</p>
            </div>
          </div>

          <div>
            <h3 className="prices font-bold mb-2 uppercase">
              INFORMATIONS GÉNÉRALES
            </h3>
            <div className="text-gray-600 space-y-1">
              <p>GENTIUM.IO - CAPITAL : 100.000 MAD</p>
              <p>RC 133559 - ICE 003239590000044 -</p>
              <p>CNSS : 466025 - IF : 5361079</p>
            </div>
          </div>
        </div>
        <div></div>
        <div className="bottom-strip"></div>
      </div>
      <div className="flex justify-center my-8 no-pdf">
        <button
          onClick={downloadPDF}
          className="px-6 py-3 bg-#640c64 text-white font-semibold rounded hover:bg-blue-700 transition"
        >
          Télécharger en PDF
        </button>
      </div>
    </div>
  );
};

export default ShowFacture;
