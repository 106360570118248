import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { AlertCircle } from "lucide-react";
import logo from "../../assets/gentuim.png";

const LOGIN_URL = '/api/login';

const Login = () => {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();

    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email: user, password: pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            const accessToken = response?.data.token;
            window.localStorage.setItem('token', accessToken);
            const roles = response?.data?.roles;

            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            navigate('/billing');
        } catch (err) {
            console.error("Error in Login Request:", err);

            if (!err.response) {
                setErrMsg('Pas de réponse du serveur');
            } else if (err.response?.status === 400) {
                setErrMsg("Nom d'utilisateur ou mot de passe manquant");
            } else if (err.response?.status === 401) {
                setErrMsg('Email ou mot de passe invalide');
            } else {
                setErrMsg('Échec de la connexion');
            }

            errRef.current?.focus();
        }
    };

    return (
        <div className="bg-gradient-to-r from-blue-50 to-stone-200 min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full">
                <form
                    onSubmit={handleSubmit}
                    className="bg-white shadow-2xl py-8 px-6 shadow-lg rounded-lg sm:px-10 space-y-6"
                >
                    <div className="flex justify-center">
                        <img src={logo} alt="Logo Gentuim" className="h-16 w-auto" />
                    </div>

                    <div className="text-center">
                        <h2 className="text-2xl font-extrabold text-purple-900">
                            Bienvenue à nouveau
                        </h2>
                        <p className="mt-1 text-sm text-gray-600">
                            Veuillez vous connecter à votre compte
                        </p>
                    </div>

                    {errMsg && (
                        <div
                            ref={errRef}
                            className="bg-red-50 border border-red-200 rounded-md p-4 animate-bounce"
                            role="alert"
                        >
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <AlertCircle className="h-5 w-5 text-red-400" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-red-700">{errMsg}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Adresse e-mail
                        </label>
                        <div className="mt-1">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                ref={userRef}
                                autoComplete="email"
                                required
                                value={user}
                                onChange={(e) => setUser(e.target.value)}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-900 focus:border-purple-900 sm:text-sm"
                                placeholder="vous@example.com"
                            />
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Mot de passe
                        </label>
                        <div className="mt-1">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-900 focus:border-purple-900 sm:text-sm"
                                placeholder="••••••••"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                        >
                            Se connecter
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
